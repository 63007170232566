import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import DynamicNavigator from "./components/DynamicNavigator";
import Home from "./pages/Home";
import PreLoader from "./pages/PreLoader";
import Projects from "./pages/Projects";
import Contact from "./pages/Contact";
import AnimatedCursor from "react-animated-cursor";

const router = createBrowserRouter([
    // _________
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "/projects",
        element: <Projects />,
    },
    {
        path: "/contact",
        element: <Contact />,
    },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <DynamicNavigator />
        <AnimatedCursor
            innerSize={14}
            outerSize={7}
            trailingSpeed={5}
            color="190, 190, 190"
            clickables={[
                "a",
                'input[type="text"]',
                'input[type="email"]',
                'input[type="number"]',
                'input[type="submit"]',
                'input[type="image"]',
                "label[for]",
                "select",
                "textarea",
                "button",
                ".link",
            ]}
        />
        <RouterProvider router={router} />
    </React.StrictMode>
);

reportWebVitals();
